import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import ADMIN_COUPON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COUPON_QUERY";
import { AdminCouponListItem } from "@sellernote/_shared/src/types/forwarding/coupon";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { convertCouponKindToKorean } from "@sellernote/_shared/src/utils/forwarding/coupon";

import Modal from "../../../../components/Modal";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "../../../../components/Table";

import FreightTypeSelectModal from "./FreightTypeSelectModal";

type CellId = keyof AdminCouponListItem;

const IssueCouponModal = ({
  opensIssueCouponModal,
  setOpensIssueCouponModal,
  teamId,
}: {
  opensIssueCouponModal: boolean;
  setOpensIssueCouponModal: (value: boolean) => void;
  teamId: number;
}) => {
  const [campaignId, setCampaignId] = useState<number | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [isAutoFetch, setIsAutoFetch] = useState(true);
  const [opensFreightTypeSelectModal, setOpensFreightTypeSelectModal] =
    useState(false);

  const { t } = useTranslation("coupon");

  const { data: couponData } = ADMIN_COUPON_QUERY.useGetAdminCouponList(
    {
      page: currentPage,
      perPage,
    },
    isAutoFetch
  );

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "campaignName",
        disablePadding: false,
        width: 150,
        label: "쿠폰명",
      },
      {
        id: "kind",
        disablePadding: false,
        label: "쿠폰형식",
      },
      {
        id: "code",
        disablePadding: false,
        label: "코드",
      },
      {
        id: "discountAmount",
        disablePadding: false,
        label: "차감액",
      },
      {
        id: "startDate",
        disablePadding: false,
        label: "시작/종료일",
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "등록일",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!couponData?.list) return [];

    return couponData?.list.map((v) => {
      const row: TableBodyRow<CellId> = {
        campaignName: (
          <Box>
            <Typography variant="body1" component={"div"}>
              {v.campaignName}
            </Typography>

            <Typography color="gray" variant="body2" component={"div"}>
              {v.comment}
            </Typography>
          </Box>
        ),
        kind: convertCouponKindToKorean({ couponKind: v.kind, t }),
        code: v.code,
        discountAmount: toThousandUnitFormat(v.discountAmount),
        startDate: `${toFormattedDate(
          v.startDate,
          "YYYY.MM.DD"
        )} / ${toFormattedDate(v.endDate, "YYYY.MM.DD")}`,
        createdAt: toFormattedDate(v.createdAt, "YYYY.MM.DD"),
        handleRowClick: () => {
          setCampaignId(v.id);
          setOpensFreightTypeSelectModal(true);
        },
      };

      return row;
    });
  }, [couponData?.list, t]);

  return (
    <Modal
      isOpened={opensIssueCouponModal}
      handleClose={() => setOpensIssueCouponModal(false)}
      modalBody={
        <>
          <Table
            title={"쿠폰 리스트"}
            headCells={headCells}
            rows={rows}
            pagination={{
              rowsPerPageOptions: [10, 25, 50, 100, 500, 1000, 10000],
              totalCount: couponData?.total || 0,
              perPage,
              setPerPage,
              currentPage,
              setCurrentPage,
            }}
          />

          {opensFreightTypeSelectModal && campaignId && (
            <FreightTypeSelectModal
              setOpensFreightTypeSelectModal={setOpensFreightTypeSelectModal}
              opensFreightTypeSelectModal={opensFreightTypeSelectModal}
              teamId={teamId}
              campaignId={campaignId}
            />
          )}
        </>
      }
    />
  );
};

export default IssueCouponModal;
