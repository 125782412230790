import { useCallback, useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { Port } from "@sellernote/_shared/src/types/common/common";
import {
  TeamBid,
  TeamSearchLog,
} from "@sellernote/_shared/src/types/forwarding/adminTeam";
import { getSupplyValueInUnit } from "@sellernote/_shared/src/utils/forwarding/bid";
import { getPortNameForDetailBidList } from "@sellernote/_shared/src/utils/forwarding/port";

import { TeamAndUserDetail } from "..";
import BidInfoTable from "./BidInfoTable";
import SearchLogTable from "./SearchLogTable";

// 현재는 검색기록과 의뢰현황이 의뢰번호를 제외하고 동일 차후 검색기록에서 추가사항이 있을 수 있음
function useBidAndSearchLogInfo({
  detailData,
  portList,
}: {
  detailData: TeamAndUserDetail | undefined;
  portList: Port[] | undefined;
}) {
  const getCargoInfo = useCallback((infoData: TeamBid | TeamSearchLog) => {
    return (
      <Box>
        <Typography variant="body2" component="div">
          {infoData.name.length > 1
            ? `${infoData.name[0]} 외 ${infoData.name.length - 1}`
            : infoData.name[0]}
        </Typography>

        <Typography variant="body2" component="div">
          {infoData.freightType}
        </Typography>

        {infoData.supply && (
          <Typography variant="body2" component="div">
            {getSupplyValueInUnit(infoData.freightType, infoData.supply)}
          </Typography>
        )}
      </Box>
    );
  }, []);

  const getShippingInfo = useCallback(
    (infoData: TeamBid | TeamSearchLog) => {
      return (
        <Box>
          <Typography variant="body2" component="div">
            {infoData.incoterms}
          </Typography>

          <Typography variant="body2" component="div">
            {getPortNameForDetailBidList(infoData, "startPortId", portList)}
            {"->"}
            {getPortNameForDetailBidList(infoData, "endPortId", portList)}
          </Typography>
        </Box>
      );
    },
    [portList]
  );

  const getCheckPointInfo = useCallback((infoData: TeamBid | TeamSearchLog) => {
    return (
      <Box>
        <Typography variant="body2" component="div">
          통관의뢰여부: {infoData.containCustoms ? "예" : "아니오"}
        </Typography>

        <Typography variant="body2" component="div">
          내륙운송 의뢰여부: {infoData.inlandType ? "예" : "아니오"}
        </Typography>
      </Box>
    );
  }, []);

  const BidInfo = useMemo(() => {
    if (!detailData) {
      return;
    }
    return (
      <BidInfoTable
        teamDetail={detailData}
        getCargoInfo={getCargoInfo}
        getShippingInfo={getShippingInfo}
        getCheckPointInfo={getCheckPointInfo}
      />
    );
  }, [detailData, getCargoInfo, getCheckPointInfo, getShippingInfo]);

  const SearchLogInfo = useMemo(() => {
    if (!detailData) {
      return;
    }
    return (
      <SearchLogTable
        teamDetail={detailData}
        getCargoInfo={getCargoInfo}
        getShippingInfo={getShippingInfo}
        getCheckPointInfo={getCheckPointInfo}
      />
    );
  }, [detailData, getCargoInfo, getCheckPointInfo, getShippingInfo]);

  return { BidInfo, SearchLogInfo };
}

export default useBidAndSearchLogInfo;
