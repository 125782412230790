import { Chip, Grid, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

import { FreightType } from "@sellernote/_shared/src/types/common/common";

function CheckPoint({
  containDomesticFee,
  containOceanSurcharge,
  containLss,
  containerAccessable,
  hopeCargoInsurance,
  freightType,
  locale,
  needIORAgency,
  needFTACertificateAgency,
}: {
  containDomesticFee: boolean;
  containOceanSurcharge: boolean;
  containLss: boolean;
  containerAccessable: boolean;
  hopeCargoInsurance: boolean;
  freightType: FreightType;
  locale: "KR" | "SG";
  needIORAgency: boolean;
  needFTACertificateAgency: boolean;
}) {
  //TODO: <Grid item>...이 중복이 되기 때문에 값을 넘기는 하나의 컴포넌트로 수정
  return (
    <Grid container spacing={2} sx={{ padding: "50px" }}>
      <Grid item xs={12}>
        <Chip color="primary" label="체크포인트" variant="outlined" />
      </Grid>

      <Grid item container spacing={4}>
        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            국내부대비용 포함
          </Typography>

          <Typography component="div" variant="body2">
            {containDomesticFee ? "예" : "아니오"}
          </Typography>
        </Grid>
        {freightType !== "AIR" && (
          <Grid item>
            <Typography
              sx={{ color: blue[800] }}
              component="div"
              variant="subtitle1"
            >
              Ocean Surcharge 포함
            </Typography>

            <Typography component="div" variant="body2">
              {containOceanSurcharge ? "예" : "아니오"}
            </Typography>
          </Grid>
        )}
        {freightType !== "AIR" && (
          <Grid item>
            <Typography
              sx={{ color: blue[800] }}
              component="div"
              variant="subtitle1"
            >
              LSS 포함
            </Typography>

            <Typography component="div" variant="body2">
              {containLss ? "예" : "아니오"}
            </Typography>
          </Grid>
        )}

        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            적하보험 가입 필요
          </Typography>

          <Typography component="div" variant="body2">
            {hopeCargoInsurance ? "예" : "아니오"}
          </Typography>
        </Grid>

        {locale === "SG" && (
          <Grid item>
            <Typography
              sx={{ color: blue[800] }}
              component="div"
              variant="subtitle1"
            >
              IOR
            </Typography>

            <Typography component="div" variant="body2">
              {needIORAgency ? "예" : "아니오"}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            FTA C/O
          </Typography>

          <Typography component="div" variant="body2">
            {needFTACertificateAgency ? "예" : "아니오"}
          </Typography>
        </Grid>
        {freightType !== "AIR" && containerAccessable && (
          <Grid item>
            <Typography
              sx={{ color: blue[800] }}
              component="div"
              variant="subtitle1"
            >
              컨테이너 진입여부
            </Typography>

            <Typography component="div" variant="body2">
              {containerAccessable ? "가능" : "불가능"}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default CheckPoint;
