import { useCallback, useRef, useState } from "react";
import { Box, Drawer } from "@mui/material";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import {
  FileCategory,
  ForwardingAdminAttachment,
} from "@sellernote/_shared/src/types/common/file";
import { BidUploadDataType } from "@sellernote/_shared/src/types/common/upload";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";

import useFileUrl from "../../../hooks/useFileUrl";

import BeforeUnloadModal from "./BeforeUnloadModal";
import FileDescription from "./FileDescription";
import FileViewer from "./FileViewer";
import FileViewerController from "./FileViewerController";

function FilePreview({
  fileName,
  s3Url,
  fileExtension,
  handleFilePreviewClose,
  visible,
  fileCategory,
  id,
  projectStatus,
  isWithdrawn,
  dataType,
  attachment,
  isPurchaseRequest,
}: {
  fileName: string;
  s3Url: string;
  fileExtension: string;
  handleFilePreviewClose: () => void;
  visible: boolean;
  fileCategory?: FileCategory;
  id?: number;
  projectStatus?: BidProjectStatus;
  isWithdrawn?: boolean;
  dataType: BidUploadDataType;
  attachment: ForwardingAdminAttachment;
  isPurchaseRequest?: boolean;
}) {
  const descriptionTextFieldRef = useRef<HTMLInputElement>(null);

  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfScale, setPdfScale] = useState(1.0);
  const [imageWidth, setImageWidth] = useState(50);
  const [showsBeforeUnloadModal, setShowsBeforeUnloadModal] = useState(false);

  const { fileUrl, isPdf, isHwp, hwpRenderDivRef } = useFileUrl(
    s3Url,
    fileExtension
  );

  const handleDocumentLoadSuccess = ({
    numPages,
  }: {
    numPages: number | null;
  }) => {
    setNumPages(numPages);
  };

  const handleBeforeUnloadModalClose = useCallback(() => {
    setShowsBeforeUnloadModal(false);
  }, []);

  const handleDrawerClose = () => {
    if (
      //처음 데이터 값이 null이고 인풋이 빈칸일 때(빈칸일 시 빈스트링)를 체크해주기 위해 추가
      !descriptionTextFieldRef.current?.value !== !attachment.description &&
      descriptionTextFieldRef.current?.value !== attachment.description
    ) {
      return setShowsBeforeUnloadModal(true);
    }

    handleFilePreviewClose();
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: "60%", paddingBottom: "30px" },
      }}
      anchor="left"
      onClose={handleDrawerClose}
      open={visible}
    >
      <Box>
        <FileViewerController
          fileName={fileName}
          s3Url={s3Url}
          fileExtension={fileExtension}
          isPdf={isPdf}
          isHwp={isHwp}
          setPageNumber={setPageNumber}
          setImageWidth={setImageWidth}
          imageWidth={imageWidth}
          pdfScale={pdfScale}
          setPdfScale={setPdfScale}
          pageNumber={pageNumber}
          numPages={numPages}
          fileCategory={fileCategory}
          id={id}
          projectStatus={projectStatus}
          isWithdrawn={isWithdrawn}
          dataType={dataType}
          attachmentId={attachment.id}
          handleFilePreviewClose={handleFilePreviewClose}
          isNewWindow={false}
          fileAuthority={attachment.authority}
          isAdmin={attachment.isAdmin}
          isPurchaseRequest={isPurchaseRequest}
        />

        <FileViewer
          isHwp={isHwp}
          isPdf={isPdf}
          fileExtension={fileExtension}
          imageWidth={imageWidth}
          fileUrl={fileUrl}
          hwpRenderDivRef={hwpRenderDivRef}
          handleDocumentLoadSuccess={handleDocumentLoadSuccess}
          pdfScale={pdfScale}
          pageNumber={pageNumber}
        />

        {dataType !== "order" && dataType !== "user" && dataType !== "team" && (
          <FileDescription
            attachmentDescription={attachment.description}
            descriptionTextFieldRef={descriptionTextFieldRef}
            attachmentId={attachment.id}
            id={id}
            attachment={attachment}
          />
        )}
      </Box>

      <BeforeUnloadModal
        handleBeforeUnloadModalClose={handleBeforeUnloadModalClose}
        showsBeforeUnloadModal={showsBeforeUnloadModal}
        handleFilePreviewClose={handleFilePreviewClose}
      />
    </Drawer>
  );
}

export default FilePreview;
