import { Chip, Grid, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

import {
  FreightType,
  InlnadTransportType,
} from "@sellernote/_shared/src/types/common/common";
import { CfsReceiving } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import { changeInlandTypeToNameKr } from "@sellernote/_shared/src/utils/forwarding/trello";

function ExportCheckPointAndAddition({
  containerDevanning,
  containerStuffing,
  cfsReceiving,
  originInland,
  useVGM,
  containCustoms,
  containExportCustoms,
  hopeCargoInsurance,
  freightType,
}: {
  containerStuffing: boolean;
  /** (수입지) 컨테이너 적출여부 */
  containerDevanning: boolean;
  /** (수출지) CFS 입고방식: 직접(고객사) 또는 대행(쉽다) */
  cfsReceiving: CfsReceiving;
  /** cfsReceiving이 대행(쉽다)일 경우 독차 또는 합차 */
  originInland: InlnadTransportType | null;

  /** VGM */
  useVGM: boolean;
  /** 수입통관 신청 여부 */
  containCustoms: boolean;
  /** 수출통관 신청 여부 */
  containExportCustoms: boolean;
  hopeCargoInsurance: boolean;
  freightType: FreightType;
}) {
  return (
    <Grid container spacing={2} sx={{ padding: "50px" }}>
      <Grid item xs={12}>
        <Chip color="primary" label="체크포인트" variant="outlined" />
      </Grid>

      <Grid item container spacing={4}>
        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            컨테이너 적입여부
          </Typography>

          <Typography component="div" variant="body2">
            {containerStuffing ? "예" : "아니오"}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            컨테이너 적출여부
          </Typography>

          <Typography component="div" variant="body2">
            {containerDevanning ? "예" : "아니오"}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            CFS 입고
          </Typography>

          <Typography component="div" variant="body2">
            {cfsReceiving === "direct"
              ? "직접"
              : cfsReceiving === "shipda"
              ? "대행"
              : "-"}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            운송방식
          </Typography>

          <Typography component="div" variant="body2">
            {changeInlandTypeToNameKr(originInland)}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Chip color="primary" label="부가서비스" variant="outlined" />
      </Grid>

      <Grid item container spacing={4}>
        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            수출통관
          </Typography>

          <Typography component="div" variant="body2">
            {containExportCustoms ? "예" : "아니오"}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            수입통관
          </Typography>

          <Typography component="div" variant="body2">
            {containCustoms ? "예" : "아니오"}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            적하보험
          </Typography>

          <Typography component="div" variant="body2">
            {hopeCargoInsurance ? "예" : "아니오"}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            VGM
          </Typography>

          <Typography component="div" variant="body2">
            {useVGM ? "예" : "아니오"}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ExportCheckPointAndAddition;
