import { useMemo } from "react";
import { Control } from "react-hook-form";
import { Box, Button } from "@mui/material";

import {
  GET_FULLFILLMENT_MANAGERS_RES,
  UPDATE_USER_DETAIL_REQ,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidUser";
import { UPDATE_TEAM_DETAIL_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminTeam";
import { GET_ADMIN_USER_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminUser";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import { Port } from "@sellernote/_shared/src/types/common/common";
import { UserDetail } from "@sellernote/_shared/src/types/forwarding/adminBidUser";
import { TeamDetail } from "@sellernote/_shared/src/types/forwarding/adminTeam";
import Tabs from "@sellernote/_shared-for-admin/src/components/Tabs";

import Layout from "../../containers/Layout";

import useCommentList from "../useCommentList";
import BasicInfo from "./BasicInfo";
import BidCreate from "./BidCreate";
import EtcInfo from "./EtcInfo";
import MemberInfo from "./MemberInfo";
import POInfo from "./POInfo";
import SalesInfo from "./SalesInfo";
import SettlementInfo from "./SettlementInfo";
import TeamInfo from "./TeamInfo";
import TeamList from "./TeamList";
import useBidAndSearchLogInfo from "./useBidAndSearchHistoryInfo";

export type TeamAndUserDetail = Partial<UserDetail & TeamDetail>;

export type DetailType = "team" | "user";

export default function useTeamAndUserDetail({
  detailData,
  portList,
  adminList,
  id,
  ResponseHandlerOfUpdateTeamDetail,
  handleTeamDetailUpdate,
  control,
  handleTeamDetailUpdateCancel,
  handleEditModeChange,
  isEditMode,
  type,
  fullfillmentManagerData,
  refetchDetailData,
  handleTeamDelete,
  ResponseHandlerOfDeleteTeam,
}: {
  detailData: TeamAndUserDetail | undefined;
  portList: Port[] | undefined;
  adminList: GET_ADMIN_USER_LIST_RES | undefined;
  id: string;
  ResponseHandlerOfUpdateTeamDetail: JSX.Element;
  handleTeamDetailUpdate: () => void;
  control: Control<UPDATE_USER_DETAIL_REQ | UPDATE_TEAM_DETAIL_REQ>;
  handleTeamDetailUpdateCancel: () => void;
  handleEditModeChange: () => void;
  isEditMode: boolean;
  type: DetailType;
  fullfillmentManagerData: GET_FULLFILLMENT_MANAGERS_RES | undefined;
  refetchDetailData: () => void;
  handleTeamDelete?: () => void;
  ResponseHandlerOfDeleteTeam?: JSX.Element;
}) {
  const { BidInfo, SearchLogInfo } = useBidAndSearchLogInfo({
    detailData,
    portList,
  });

  const { CommentList } = useCommentList({
    commentList: detailData?.comments,
    dataId: Number(id),
    dataType: type,
  });

  const Detail = useMemo(() => {
    if (!detailData || !adminList || !portList || !fullfillmentManagerData) {
      return (
        <Layout>
          <Loading active={true} />
        </Layout>
      );
    }

    return (
      <Layout title={type === "user" ? "사용자 상세" : "팀 상세"}>
        <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
          {!isEditMode && (
            <Button variant="outlined" onClick={handleEditModeChange}>
              수정
            </Button>
          )}

          {isEditMode && (
            <Box>
              <Button variant="contained" onClick={handleTeamDetailUpdate}>
                확인
              </Button>

              <Button
                variant="contained"
                color="error"
                onClick={handleTeamDetailUpdateCancel}
              >
                취소
              </Button>
            </Box>
          )}

          {handleTeamDelete && !isEditMode && (
            <Button
              variant="contained"
              color="error"
              onClick={handleTeamDelete}
            >
              삭제
            </Button>
          )}

          <BidCreate
            userId={detailData.userId}
            joinedAllTeam={detailData.joinedAllTeam}
            userName={detailData.userName}
            /** type이 사용자상세(user)라면 teamId가 있음 팀이라면 id가 팀의 id */
            teamId={type === "user" ? detailData.teamId : detailData.id}
            corpSizeType={detailData.corpSizeType}
            isUserCreate={type === "user"}
            teamName={detailData.teamName}
            teamMemberList={detailData.members}
          />
        </Box>

        <BasicInfo
          detailData={detailData}
          adminList={adminList}
          type={type}
          fullfillmentManagerData={fullfillmentManagerData}
          refetchDetailData={refetchDetailData}
          isEditMode={isEditMode}
          control={control}
        />

        <SettlementInfo
          detailData={detailData}
          control={control}
          isEditMode={isEditMode}
          type={type}
        />

        <EtcInfo
          type={type}
          detailData={detailData}
          control={control}
          isEditMode={isEditMode}
        />

        <SalesInfo type={type} detailData={detailData} />

        <Tabs
          tabsData={[
            {
              tabLabel: "의뢰현황",
              tabPanelItem: BidInfo,
            },
            {
              tabLabel: "검색기록",
              tabPanelItem: SearchLogInfo,
            },
            {
              tabLabel: "발주현황",
              tabPanelItem: <POInfo detailData={detailData} type={type} />,
            },
            {
              tabLabel: "메모",
              tabPanelItem: CommentList,
            },
          ]}
        />

        {type === "team" && <MemberInfo detailData={detailData} />}

        {type === "user" && <TeamInfo detailData={detailData} />}

        {type === "user" && <TeamList detailData={detailData} />}

        {ResponseHandlerOfUpdateTeamDetail}
        {ResponseHandlerOfDeleteTeam}
      </Layout>
    );
  }, [
    BidInfo,
    CommentList,
    ResponseHandlerOfDeleteTeam,
    ResponseHandlerOfUpdateTeamDetail,
    SearchLogInfo,
    adminList,
    control,
    detailData,
    fullfillmentManagerData,
    handleEditModeChange,
    handleTeamDelete,
    handleTeamDetailUpdate,
    handleTeamDetailUpdateCancel,
    isEditMode,
    portList,
    refetchDetailData,
    type,
  ]);

  return { Detail };
}
