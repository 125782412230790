import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
} from "@mui/material";

export type CheckboxWithReactHookFormProps<T extends FieldValues> = Omit<
  FormControlLabelProps,
  // FormControlLabel에 control과 겹쳐서 제외
  "control" | "defaultValue"
> & {
  name: Path<T>;
  control?: Control<T>;
  label?: string;
  defaultValue?: PathValue<T, (string | undefined) & Path<T>>;
};

export default function CheckboxWithReactHookForm<T extends FieldValues>({
  name,
  control,
  label,
  defaultValue,
  ...props
}: CheckboxWithReactHookFormProps<T>) {
  return (
    <FormControlLabel
      control={
        <Controller
          {...props}
          name={name}
          defaultValue={defaultValue}
          control={control}
          render={({ field }) => (
            <Checkbox
              disabled={props?.disabled}
              {...field}
              checked={props.value ? !!props.value : !!field.value}
              onChange={field.onChange}
            />
          )}
        />
      }
      label={label}
    />
  );
}
