import AdminDetailDescription from "../../../components/AdminDetailDescription";

import { TeamAndUserDetail } from "..";

function TeamInfo({ detailData }: { detailData: TeamAndUserDetail }) {
  return (
    <AdminDetailDescription
      title={"팀 정보"}
      descriptionValueList={[
        { label: "합계", value: detailData.teamCnt },
        { label: "팀 마스터", value: detailData.isMasterCnt },
        { label: "팀 마스터 외", value: detailData.isMemberCnt },
      ]}
    />
  );
}

export default TeamInfo;
