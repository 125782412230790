import AdminDetailDescription from "@sellernote/_shared-for-forwarding-admin/src/components/AdminDetailDescription";

import { DetailType, TeamAndUserDetail } from "..";

function SalesInfo({
  detailData,
  type,
}: {
  detailData: TeamAndUserDetail;
  type: DetailType;
}) {
  return (
    <AdminDetailDescription
      title={"영업정보"}
      descriptionValueList={[
        { label: "검색건수", value: detailData.searchCount },
        { label: "의뢰건수", value: detailData.bidCount },
        { label: "진행건수", value: detailData.inProgressBidCount },
        { label: "제3자 동의", value: detailData.remittanceAgree ? "O" : "X" },
      ].filter((v) => {
        if (type === "team") {
          return v.label === "제3자 동의";
        }
        return v;
      })}
    />
  );
}

export default SalesInfo;
