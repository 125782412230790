const FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD = {
  // 부모인 Dialog의 기본값이 1300이기 때문에 헤더필터의 Popper와 paper를 1300으로 설정
  tableHeaderFilter: 1300,
  // 파일 미리보기 Drawer의 zIndex 기본값이 1200이지만 Modal은 1300이라 해당 모달만 1200보다 낮게 설정
  fileUploadModal: 1100,
  // 거래처 관리 > 상세 > 모달에서 팀,파트너 모달이 폼 모달뒤에 생겨 기본값 1300보다 높게 설정
  accountManagementDetailTeamAndPartnerModal: 1301,
  // 거래처 관리 > 상세 > 모달 > 팀, 파트너 모달 > 파일 업로드, 검색, 파일 미리보기에서 중첩이 됨 팀,파트너 모달보다 높게 설정
  accountManagementDetailNestedModal: 1302,
  // snackbar는 뷰 최상단에 보이기 위해 가장 높은 값으로 설정
  snackbar: 9999,
  // 거래명세서 항목 체크박스의 기본 z-index가 1이라 고정바 버튼 클릭시 문제가 생겨 설정
  tradingStatementBottomSticky: 2,
};

export { FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD };
