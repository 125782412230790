import { useMemo, useState } from "react";

import {
  TeamBid,
  TeamSearchLog,
} from "@sellernote/_shared/src/types/forwarding/adminTeam";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "../../../components/LegacyTable";

import { TeamAndUserDetail } from "..";

type CellId = keyof TeamSearchLog | "cargoInfo" | "shippingInfo" | "checkpoint";

function SearchLogTable({
  teamDetail,
  getCargoInfo,
  getShippingInfo,
  getCheckPointInfo,
}: {
  teamDetail: TeamAndUserDetail;
  getCargoInfo: (infoData: TeamBid | TeamSearchLog) => JSX.Element;
  getShippingInfo: (infoData: TeamBid | TeamSearchLog) => JSX.Element;
  getCheckPointInfo: (infoData: TeamBid | TeamSearchLog) => JSX.Element;
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);

  const HeadCells = useMemo(() => {
    const headCells: LegacyTableHeadCell<CellId>[] = [
      {
        id: "userName",
        disablePadding: false,
        label: "담당자명",
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "검색 일시",
      },
      {
        id: "cargoInfo",
        disablePadding: false,
        label: "화물정보",
      },
      {
        id: "shippingInfo",
        disablePadding: false,
        label: "운송정보",
      },
      {
        id: "checkpoint",
        disablePadding: false,
        label: "체크포인트",
      },
    ];
    return headCells;
  }, []);

  const rows = useMemo(() => {
    if (!teamDetail.searchLogs) return [];

    return (
      teamDetail.searchLogs
        // API 요청이 없어서 자체 페이지네이션을 한다.
        .slice(currentPage * perPage, currentPage * perPage + perPage)
        .map((v) => {
          const row: LegacyTableBodyRow<CellId> = {
            userName: v.userName || teamDetail.userName,
            createdAt: toFormattedDate(v.createdAt, "YYYY.MM.DD a hh:mm"),
            cargoInfo: getCargoInfo(v),
            shippingInfo: getShippingInfo(v),
            checkpoint: getCheckPointInfo(v),
          };

          return row;
        })
    );
  }, [
    currentPage,
    getCargoInfo,
    getCheckPointInfo,
    getShippingInfo,
    perPage,
    teamDetail.searchLogs,
    teamDetail.userName,
  ]);

  return (
    <LegacyTable
      title={"검색기록"}
      headCells={HeadCells}
      rows={rows}
      pagination={{
        rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
        totalCount: teamDetail?.searchLogs?.length || 0,
        perPage,
        setPerPage,
        currentPage,
        setCurrentPage,
      }}
    />
  );
}

export default SearchLogTable;
