import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import useHistoryState from "@sellernote/_shared/src/hooks/common/useHistoryState";
import {
  TeamBid,
  TeamSearchLog,
} from "@sellernote/_shared/src/types/forwarding/adminTeam";
import {
  changeBidProjectStatusNameToKr,
  changeBidStatusNameToKr,
} from "@sellernote/_shared/src/utils/forwarding/bid";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "../../../components/LegacyTable";

import { TeamAndUserDetail } from "..";

type CellId = keyof TeamBid | "cargoInfo" | "shippingInfo" | "checkpoint";

function BidInfoTable({
  teamDetail,
  getCargoInfo,
  getShippingInfo,
  getCheckPointInfo,
}: {
  teamDetail: TeamAndUserDetail;
  getCargoInfo: (infoData: TeamBid | TeamSearchLog) => JSX.Element;
  getShippingInfo: (infoData: TeamBid | TeamSearchLog) => JSX.Element;
  getCheckPointInfo: (infoData: TeamBid | TeamSearchLog) => JSX.Element;
}) {
  const history = useHistory();

  const [currentPage, setCurrentPage] = useHistoryState("userBidTablePage", 0);
  const [perPage, setPerPage] = useHistoryState("userBidTablePerPage", 20);

  const handleBidDetailMove = useCallback(
    (bidId: number) => {
      return history.push(`/bid/detail/${bidId}`);
    },
    [history]
  );

  const HeadCells = useMemo(() => {
    const headCells: LegacyTableHeadCell<CellId>[] = [
      {
        id: "id",
        disablePadding: false,
        label: "운송의뢰번호",
      },
      {
        id: "userName",
        disablePadding: false,
        label: "담당자명",
        width: 100,
      },
      {
        id: "cargoInfo",
        disablePadding: false,
        label: "화물정보",
        width: 180,
      },
      {
        id: "shippingInfo",
        disablePadding: false,
        label: "운송정보",
      },
      {
        id: "checkpoint",
        disablePadding: false,
        label: "체크포인트",
        width: 180,
      },
      {
        id: "status",
        disablePadding: false,
        label: "상태",
      },
      {
        id: "projectStatus",
        disablePadding: false,
        label: "세부 상태",
      },
    ];
    return headCells;
  }, []);

  const rows = useMemo(() => {
    if (!teamDetail.bids) return [];

    return (
      teamDetail.bids
        // API 요청이 없어서 자체 페이지네이션을 한다.
        .slice(currentPage * perPage, currentPage * perPage + perPage)
        .map((v) => {
          const row: LegacyTableBodyRow<CellId> = {
            handleRowClick: () => handleBidDetailMove(v.id),
            id: v.id,
            userName: v.userName || teamDetail.userName,
            cargoInfo: getCargoInfo(v),
            shippingInfo: getShippingInfo(v),
            checkpoint: getCheckPointInfo(v),
            status: changeBidStatusNameToKr(v.status),
            projectStatus: changeBidProjectStatusNameToKr(v.projectStatus),
          };

          return row;
        })
    );
  }, [
    currentPage,
    getCargoInfo,
    getCheckPointInfo,
    getShippingInfo,
    handleBidDetailMove,
    perPage,
    teamDetail.bids,
    teamDetail.userName,
  ]);

  return (
    <LegacyTable
      title={"의뢰현황"}
      headCells={HeadCells}
      rows={rows}
      pagination={{
        rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
        totalCount: teamDetail?.bids?.length || 0,
        perPage,
        setPerPage,
        currentPage,
        setCurrentPage,
      }}
    />
  );
}

export default BidInfoTable;
