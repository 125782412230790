import { useMemo } from "react";
import {
  Control,
  Controller,
  DeepRequired,
  FieldErrorsImpl,
} from "react-hook-form";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  FreightType,
  Liner,
} from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import TextField from "@sellernote/_shared-for-admin/src/components/TextField";
import AutoCompleteWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/AutoCompleteWithReactHookForm";
import SelectWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/SelectWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

function EtcFormList({
  control,
  errors,
  linerList,
  freightType,
  showsAnotherCommentCheck,
}: {
  control: Control<ApplyBidFormData>;
  errors: FieldErrorsImpl<DeepRequired<ApplyBidFormData>>;
  linerList: Liner[] | undefined;
  freightType: FreightType;
  showsAnotherCommentCheck?: boolean;
}) {
  const linerListForAutoCompleteOptions = useMemo(() => {
    if (!linerList) {
      return [];
    }
    return linerList.map((v) => {
      return v.name;
    });
  }, [linerList]);

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={2}>
          <AutoCompleteWithReactHookForm
            label="선사"
            name={"liner"}
            control={control}
            options={linerListForAutoCompleteOptions}
            required={freightType !== "LCL"}
          />
        </Grid>

        <Grid item xs={2}>
          {/* TODO: 공용컴포넌트로 수정하기 */}
          <Controller
            control={control}
            name={"expiredAt"}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => {
              return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    {...field}
                    componentsProps={{
                      actionBar: { actions: ["clear"] },
                    }}
                    value={field.value}
                    inputFormat="yyyy-MM-dd"
                    label="견적 만료일"
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        error={errors && errors.expiredAt?.type === "required"}
                        helperText={
                          errors &&
                          errors.expiredAt?.type === "required" &&
                          "필수 입력입니다."
                        }
                        onKeyDown={(event) => {
                          event.preventDefault();
                        }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              );
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <SelectWithReactHookForm
            label="지급 유형"
            name={"freightPaymentType"}
            control={control}
            defaultValue={"cc"}
            options={["cc", "pp"]}
            required={true}
          />
        </Grid>

        <Grid item xs={2}>
          <TextFieldWithReactHookForm
            name={"leadtime"}
            label="소요일"
            control={control}
            type={"number"}
            required={freightType !== "LCL"}
          />
        </Grid>

        <Grid item xs={2}>
          <Controller
            control={control}
            name={"isTransit"}
            render={({ field: { ref, ...field } }) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputRef={ref}
                      checked={!!field.value}
                      onChange={field.onChange}
                    />
                  }
                  label="환적 여부"
                />
              );
            }}
          />
        </Grid>
      </Grid>

      {showsAnotherCommentCheck && freightType === "FCL" && (
        <Grid item xs={12}>
          <Controller
            control={control}
            name={"anotherPortInlandOptionComment"}
            render={({ field: { ref, ...field } }) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputRef={ref}
                      checked={!!field.value}
                      onChange={field.onChange}
                    />
                  }
                  label="접안항 코멘트 여부"
                />
              );
            }}
          />
        </Grid>
      )}

      <Grid item container xs={12}>
        <Grid item xs={10}>
          <TextFieldWithReactHookForm
            label="코멘트"
            name={"comment"}
            control={control}
            type={"string"}
            fullWidth
            multiline
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EtcFormList;
